import React from 'react'
import { Layout, Posts, SEO, TagCloudCustomized, TagsFromAtoZ } from '../components'
import { graphql } from 'gatsby'

const TagsPage = ({ data }) => {
  const {
    getAllPosts: { nodes: posts },
    getAllProjects: { nodes: projects },
    getAllTags: { nodes: tags },
  } = data

  return (
    <Layout>
      <SEO title="태그" />
      <TagCloudCustomized title="태그 클라우드" tags={tags} />
      <TagsFromAtoZ title="태그 ㄱ-ㅎ & A-Z 정렬" all={tags} />
      <Posts posts={posts} title="글" />
      <Posts posts={projects} title="프로젝트" />
    </Layout>
  )
}

export const query = graphql`
  {
    getAllPosts: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { division: { eq: "post" }, published: { eq: true } }
      }
    ) {
      nodes {
        excerpt(pruneLength: 100, truncate: true)
        frontmatter {
          title
          subtitle
          author
          division
          category
          subcategory
          tags
          date(formatString: "YYYY-MM-DD")
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        id
        timeToRead
      }
    }
    getAllProjects: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { division: { eq: "project" }, published: { eq: true } }
      }
    ) {
      nodes {
        excerpt
        frontmatter {
          title
          subtitle
          author
          division
          category
          subcategory
          tags
          date(formatString: "YYYY-MM-DD")
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        id
        timeToRead
      }
    }
    getAllTags: allMdx(filter: { frontmatter: { published: { eq: true } } }) {
      nodes {
        frontmatter {
          tags
        }
      }
    }
  }
`

export default TagsPage
